var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topbar-item"},[_c('Toast',{attrs:{"position":"top-center","group":"notif"}}),_c('div',{staticClass:"notification-bell-container"},[_c('div',{staticClass:"notification-backdrop",class:{ 'd-none': !_vm.is_notification_body_open },on:{"click":_vm.toggleNotificacoes}}),_c('div',{staticClass:"notification-bell-ball",on:{"click":_vm.toggleNotificacoes}},[_c('i',{staticClass:"fa-solid fa-bell",class:{ 'notification-unread': _vm.unreadNotifications.length > 0 }}),(_vm.unreadNotifications.length > 0)?_c('div',{staticClass:"new-notification-news-ball"},[_c('span',[_vm._v(_vm._s(_vm.unreadNotifications.length))])]):_vm._e()]),(_vm.is_notification_body_open)?_c('div',{staticClass:"notification-body-container"},[(_vm.notificacoes_iniciais.length == 0)?_c('div',[_vm._m(0)]):_vm._e(),_vm._l((_vm.notificacoes_iniciais),function(notificacao){return _c('div',{key:'notif-' + notificacao.novidade_id,staticClass:"notification-body-item",class:{
          'notification-unread': !notificacao.nus_visualizado
        },on:{"click":function () { return _vm.clickNotificacao(notificacao); }}},[_c('div',{staticClass:"notification-body-item-title"},[_vm._v(" "+_vm._s(notificacao.nov_titulo)+" ")]),_c('div',{staticClass:"notification-body-item-description"},[_vm._v(" "+_vm._s(notificacao.nov_descricao)+" ")]),_c('div',{staticClass:"notification-body-item-date"},[_vm._v(" "+_vm._s(_vm.parseDataFormatoBrasileiro(notificacao.created_at))+" ")]),(!notificacao.nus_visualizado)?_c('div',{staticClass:"unread-notification-ball"}):_vm._e()])})],2):_vm._e()]),_c('div',{staticClass:"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2",attrs:{"id":"kt_quick_user_toggle"}},[_c('span',{staticClass:"text-dark font-weight-bold font-size-base d-none d-md-inline mr-3"},[_vm._v(" Conexões do Whatsapp ")]),_c('span',{staticClass:"symbol symbol-35",class:{
        'symbol-success': !_vm.contas[0].validacaoChat && _vm.contas[0].cca_ativo,
        'symbol-danger': _vm.contas[0].validacaoChat || !_vm.contas[0].cca_ativo
      }},[_c('span',{staticClass:"symbol-label"},[_c('i',{staticClass:"fas icon-lg text-white",class:{
            'fa-comment-dots':
              !_vm.contas[0].validacaoChat && _vm.contas[0].cca_ativo,
            'fa-comment-slash':
              _vm.contas[0].validacaoChat || !_vm.contas[0].cca_ativo
          }})])])]),_c('div',{ref:"kt_quick_user",staticClass:"offcanvas offcanvas-right p-5",attrs:{"id":"kt_quick_user"}},[_vm._m(1),_c('perfect-scrollbar',{staticClass:"offcanvas-content pr-5 mr-n5 scroll",staticStyle:{"max-height":"90vh","position":"relative"}},[_c('div',{staticClass:"d-flex align-items-center mt-5"},[_c('div',{staticClass:"symbol symbol-70 mr-2"},[_c('img',{staticClass:"symbol-label",attrs:{"src":_vm.currentUserPersonalInfo.foto,"alt":""}}),_c('i',{staticClass:"symbol-badge bg-success"})]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold font-size-h5 text-dark-75"},[_vm._v(" "+_vm._s(_vm.currentUserPersonalInfo.nome)+" ")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.currentUserPersonalInfo.role.description)+" ")]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.currentUserPersonalInfo.login)+" ")])])]),_c('div',{staticClass:"row mt-2 p-0 m-0"},[_c('div',{staticClass:"col p-0 mr-2"},[_c('modal-perfil',{attrs:{"usuario":Object.assign({}, _vm.currentUserPersonalInfo),"tipoBotao":"botao","botaoAcoes":false,"variant":"light-success"}})],1),_c('div',{staticClass:"col p-0"},[_c('b-button',{attrs:{"variant":"light-danger","block":""},on:{"click":_vm.onLogout}},[_vm._v(" Sair ")])],1)]),_c('div',{staticClass:"separator separator-dashed mt-8 mb-5"}),(_vm.exibirCanais)?_c('div',{staticClass:"navi navi-spacer-x-0 p-0"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Conexão ao Whatsapp:")]),_vm._l((_vm.contas),function(conta,index){return _c('div',{key:index,staticClass:"navi-item"},[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Conta:")]),_vm._v(" "+_vm._s(conta.login)+" "),(conta.whatsappOficial)?_c('img',{staticStyle:{"width":"16px"},attrs:{"src":'/media/comunicante/meta-logo.png'}}):_vm._e()]),_c('div',{staticClass:"navi-link pt-0"},[_c('div',{staticClass:"symbol symbol-50 bg-light mr-3 border"},[_c('img',{attrs:{"src":conta.picture
                    ? conta.picture
                    : '/media/comunicante/logo-whats-cinza.png'}})]),_c('div',{staticClass:"navi-text"},[(!conta.validacaoChat)?[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(conta.pushname))]),_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(conta.phone)+" ")])]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.5rem"}},[(conta.whatsappOficial)?[_c('modal-configuracoes-whatsapp-oficial',{attrs:{"conta":conta}})]:[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.left",modifiers:{"noninteractive":true,"hover":true,"left":true}}],staticClass:"btn btn-icon btn-light-success mr-1",class:{
                          'btn-light-success': conta.esquenta_chip_ativo,
                          'btn-light-danger': !conta.esquenta_chip_ativo
                        },attrs:{"type":"button","title":'Wellon Booster ' +
                          (conta.esquenta_chip_ativo ? 'Ativo' : 'Inativo')},on:{"click":function($event){return _vm.toggleEsquentaChip(index, conta)}}},[_c('i',{staticClass:"fa-solid fa-bolt",style:({
                            color: conta.esquenta_chip_ativo
                              ? '#ffa800'
                              : '#b0b3b6'
                          })})])],_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.left",modifiers:{"noninteractive":true,"hover":true,"left":true}}],staticClass:"btn btn-icon btn-light-primary mr-1",attrs:{"type":"button","title":"Copiar Link","id":("btnCopiarLinkApi" + index)},on:{"click":function($event){return _vm.copiarLinkApi(index, conta.login, conta.senha)}}},[_c('i',{staticClass:"fas fa-link"}),_c('b-tooltip',{ref:("tooltipCopiarLinkApi" + index),refInFor:true,attrs:{"target":("btnCopiarLinkApi" + index),"disabled":""}},[_vm._v(" Copiado! ")])],1),(!conta.whatsappOficial)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.left",modifiers:{"noninteractive":true,"hover":true,"left":true}}],staticClass:"btn btn-icon btn-light-info",attrs:{"type":"button","title":"Reiniciar"},on:{"click":function($event){$event.preventDefault();return _vm.reiniciarWhatsapp(conta)}}},[_c('i',{staticClass:"flaticon2-reload"})]):_vm._e()],2)])]:_vm._e(),(conta.validacaoChat)?[_c('modal-qrCode',{attrs:{"conta":conta,"tipoBotao":"botao","textoBotao":"Clique aqui para conectar!","variant":"info","gestorCanais":true}})]:_vm._e()],2)])])})],2):_vm._e()])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification-body-item"},[_c('div',{staticClass:"notification-body-item-title"},[_vm._v("Nenhuma notificação")]),_c('div',{staticClass:"notification-body-item-description"},[_vm._v(" Você não possui nenhuma notificação. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offcanvas-header d-flex align-items-center justify-content-between pb-5"},[_c('h3',{staticClass:"font-weight-bold m-0"},[_vm._v("Perfil")]),_c('a',{staticClass:"btn btn-xs btn-icon btn-light btn-hover-primary",attrs:{"href":"#","id":"kt_quick_user_close"}},[_c('i',{staticClass:"ki ki-close icon-xs text-muted"})])])}]

export { render, staticRenderFns }