<template>
  <div class="topbar-item">
    <Toast position="top-center" group="notif" />
    <div class="notification-bell-container">
      <div
        class="notification-backdrop"
        :class="{ 'd-none': !is_notification_body_open }"
        @click="toggleNotificacoes"
      ></div>
      <div class="notification-bell-ball" @click="toggleNotificacoes">
        <i
          class="fa-solid fa-bell"
          :class="{ 'notification-unread': unreadNotifications.length > 0 }"
        ></i>
        <div
          class="new-notification-news-ball"
          v-if="unreadNotifications.length > 0"
        >
          <span>{{ unreadNotifications.length }}</span>
        </div>
      </div>
      <div class="notification-body-container" v-if="is_notification_body_open">
        <div v-if="notificacoes_iniciais.length == 0">
          <div class="notification-body-item">
            <div class="notification-body-item-title">Nenhuma notificação</div>
            <div class="notification-body-item-description">
              Você não possui nenhuma notificação.
            </div>
          </div>
        </div>
        <div
          class="notification-body-item"
          v-for="notificacao in notificacoes_iniciais"
          :key="'notif-' + notificacao.novidade_id"
          :class="{
            'notification-unread': !notificacao.nus_visualizado
          }"
          @click="() => clickNotificacao(notificacao)"
        >
          <div class="notification-body-item-title">
            {{ notificacao.nov_titulo }}
          </div>
          <div class="notification-body-item-description">
            {{ notificacao.nov_descricao }}
          </div>
          <div class="notification-body-item-date">
            {{ parseDataFormatoBrasileiro(notificacao.created_at) }}
          </div>
          <div
            class="unread-notification-ball"
            v-if="!notificacao.nus_visualizado"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-dark font-weight-bold font-size-base d-none d-md-inline mr-3"
      >
        Conexões do Whatsapp
      </span>
      <span
        class="symbol symbol-35"
        v-bind:class="{
          'symbol-success': !contas[0].validacaoChat && contas[0].cca_ativo,
          'symbol-danger': contas[0].validacaoChat || !contas[0].cca_ativo
        }"
      >
        <span class="symbol-label">
          <i
            class="fas icon-lg text-white"
            v-bind:class="{
              'fa-comment-dots':
                !contas[0].validacaoChat && contas[0].cca_ativo,
              'fa-comment-slash':
                contas[0].validacaoChat || !contas[0].cca_ativo
            }"
          ></i>
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-5"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">Perfil</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-70 mr-2">
            <img
              class="symbol-label"
              :src="currentUserPersonalInfo.foto"
              alt=""
            />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <span class="font-weight-bold font-size-h5 text-dark-75">
              {{ currentUserPersonalInfo.nome }}
            </span>
            <span class="text-muted">
              {{ currentUserPersonalInfo.role.description }}
            </span>

            <span class="text-muted">
              {{ currentUserPersonalInfo.login }}
            </span>
          </div>
        </div>
        <div class="row mt-2 p-0 m-0">
          <div class="col p-0 mr-2">
            <modal-perfil
              :usuario="{ ...currentUserPersonalInfo }"
              tipoBotao="botao"
              :botaoAcoes="false"
              variant="light-success"
            ></modal-perfil>
          </div>
          <div class="col p-0">
            <b-button variant="light-danger" block @click="onLogout">
              Sair
            </b-button>
          </div>
        </div>
        <!--end::Header-->

        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!-- <div class="d-flex mt-1" v-if="isAdmin">
          <button
            class="btn btn-danger btn-bold flex-fill ml-2"
            @click="onBroadCast"
          >
            BroadCast Whatsapp Desconectado
          </button>
        </div> -->

        <div class="navi navi-spacer-x-0 p-0" v-if="exibirCanais">
          <div class="font-weight-bold">Conexão ao Whatsapp:</div>
          <div class="navi-item" v-for="(conta, index) in contas" :key="index">
            <p class="mb-0">
              <span class="font-weight-bold">Conta:</span> {{ conta.login }}
              <img
                v-if="conta.whatsappOficial"
                :src="'/media/comunicante/meta-logo.png'"
                style="width: 16px"
              />
            </p>
            <div class="navi-link pt-0">
              <div class="symbol symbol-50 bg-light mr-3 border">
                <img
                  :src="
                    conta.picture
                      ? conta.picture
                      : '/media/comunicante/logo-whats-cinza.png'
                  "
                />
              </div>
              <div class="navi-text">
                <template v-if="!conta.validacaoChat">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div class="font-weight-bold">{{ conta.pushname }}</div>
                      <div class="text-muted">
                        {{ conta.phone }}
                      </div>
                    </div>
                    <div class="d-flex" style="gap: 0.5rem">
                      <template v-if="conta.whatsappOficial">
                        <modal-configuracoes-whatsapp-oficial
                          :conta="conta"
                        ></modal-configuracoes-whatsapp-oficial>
                      </template>
                      <template v-else>
                        <button
                          class="btn btn-icon btn-light-success mr-1"
                          :class="{
                            'btn-light-success': conta.esquenta_chip_ativo,
                            'btn-light-danger': !conta.esquenta_chip_ativo
                          }"
                          type="button"
                          v-b-tooltip.noninteractive.hover.left
                          :title="
                            'Wellon Booster ' +
                            (conta.esquenta_chip_ativo ? 'Ativo' : 'Inativo')
                          "
                          @click="toggleEsquentaChip(index, conta)"
                        >
                          <i
                            class="fa-solid fa-bolt"
                            :style="{
                              color: conta.esquenta_chip_ativo
                                ? '#ffa800'
                                : '#b0b3b6'
                            }"
                          ></i>
                        </button>
                      </template>

                      <button
                        class="btn btn-icon btn-light-primary mr-1"
                        type="button"
                        v-b-tooltip.noninteractive.hover.left
                        title="Copiar Link"
                        @click="copiarLinkApi(index, conta.login, conta.senha)"
                        :id="`btnCopiarLinkApi${index}`"
                      >
                        <i class="fas fa-link"></i>

                        <b-tooltip
                          :ref="`tooltipCopiarLinkApi${index}`"
                          :target="`btnCopiarLinkApi${index}`"
                          disabled
                        >
                          Copiado!
                        </b-tooltip>
                      </button>

                      <button
                        v-if="!conta.whatsappOficial"
                        class="btn btn-icon btn-light-info"
                        type="button"
                        v-b-tooltip.noninteractive.hover.left
                        title="Reiniciar"
                        @click.prevent="reiniciarWhatsapp(conta)"
                      >
                        <i class="flaticon2-reload"></i>
                      </button>
                    </div>
                  </div>
                </template>

                <template v-if="conta.validacaoChat">
                  <modal-qrCode
                    :conta="conta"
                    tipoBotao="botao"
                    textoBotao="Clique aqui para conectar!"
                    variant="info"
                    :gestorCanais="true"
                  ></modal-qrCode>
                </template>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
.navi .navi-item .navi-link:hover .navi-text {
  color: #3f4254 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";
import Perfil from "@/view/components/Perfil";
import moment from "moment";
import API_LINKS from "../../../pages/painel/automacao/api.links";
import Toast from "primevue/toast";

import ModalConfiguracoesWhatsappOficial from "./ModalConfiguracoesWhatsappOficial";

export default {
  name: "KTQuickUser",
  data() {
    return {
      shortPollingNotificationIntervalMS: 15000,
      stillRequestingNotifications: false,
      frmAlterarSenha: {
        senhaAtual: "",
        novaSenha: "",
        alterarNovaSenha: ""
      },
      contas: [{ validacaoChat: true, cca_ativo: false }],
      timeout: null,
      exibirConexaoContas: true,
      conta_just_added_today: false,
      is_notification_body_open: false,
      notificacoes_iniciais: [],
      notifications_interval: null
    };
  },

  components: {
    "modal-perfil": Perfil,
    Toast,
    "modal-configuracoes-whatsapp-oficial": ModalConfiguracoesWhatsappOficial
  },

  mounted() {
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    const self = this;
    this.$bus.$on("logout:usuario", function () {
      self.onLogout();
    });
    this.notificationsShortPolling();
  },
  beforeDestroy() {
    clearInterval(this.notifications_interval);
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "isAdmin", "hasPermission"]),
    exibirCanais() {
      if (!this.isAdmin && this.hasPermission("conectar-aparelho")) {
        if (this.exibirConexaoContas) {
          this.inicializar();
          this.desativarConexaoContas();
        }
        return true;
      }
      return false;
    },
    unreadNotifications() {
      return this.notificacoes_iniciais.filter(
        (notificacao) => !notificacao.nus_visualizado
      );
    }
  },

  methods: {
    inicializar() {
      ApiService.post(
        "/dashboard/info",
        {
          idConta: null,
          exibirSubcontas: true
        },
        false
      ).then((response) => {
        const contas = response.data.data.map((conta) => {
          if (conta.data_primeira_ativacao && !conta.whatsappOficial) {
            const ativacao = moment(
              conta.data_primeira_ativacao,
              "YYYY-MM-DD HH:mm:ss"
            );
            const today = moment();
            const seven_days_ago = moment().subtract(7, "day");
            const dont_show_booster = localStorage.getItem("dont_show_booster");
            const flag_mostrar_booster = dont_show_booster
              ? moment(dont_show_booster, "YYYY-MM-DD") <= today
              : true;

            if (flag_mostrar_booster && ativacao > seven_days_ago) {
              Swal.fire({
                title: "Atenção!",
                text: "Você tem um número que foi ativo recentemente, você pode ativar o Wellon Booster para ele, para isso, basta clicar no botão de Conexões de Whatsapp.",
                icon: "warning",
                confirmButtonText: "Ok",
                cancelButtonText: "Não exibir novamente",
                showCancelButton: true,
                buttonsStyling: true
              }).then((result) => {
                if (result.dismiss) {
                  localStorage.setItem(
                    "dont_show_booster",
                    moment().add(1, "day").format("YYYY-MM-DD")
                  );
                }
              });
            }
          }
          return conta;
        });
        if (contas.length > 0) {
          this.contas = contas;
        }
      });
    },
    onBroadCast() {
      Swal.fire({
        title: "Deseja realmente usar o Botão de BroadCast?",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.post(
            "/administrativo/broadcast-connection-closed",
            {},
            false
          ).then((response) => {});
        }
      });
    },
    onLogout() {
      Swal.fire({
        title: "Deseja realmente sair?",
        showCancelButton: true,
        confirmButtonText: "Sair",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          if (window.$chatwoot) {
            window.$chatwoot.toggleBubbleVisibility("hide");
          }
          this.$store.dispatch(LOGOUT, true).then(() => {
            this.$router.push({ name: "login" });
          });
        }
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    mudarVisibilidade(id, e) {
      const campo = document.getElementById(id);
      const icone = e.currentTarget.firstChild;

      if (campo.getAttribute("type") === "password") {
        campo.setAttribute("type", "text");
        icone.classList.add("fa-eye-slash");
        icone.classList.remove("fa-eye");
      } else {
        campo.setAttribute("type", "password");
        icone.classList.add("fa-eye");
        icone.classList.remove("fa-eye-slash");
      }
    },
    usarSenha(senha) {
      this.frmAlterarSenha.novaSenha = senha;
      this.frmAlterarSenha.confirmarNovaSenha = senha;
    },
    reiniciarWhatsapp(conta) {
      const payload = {
        conta_id: conta.id
      };
      Swal.fire({
        title: "Deseja realmente reiniciar o Whatsapp?",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.reiniciarWhatsappConfirmado(payload);
        }
      });
    },
    reiniciarWhatsappConfirmado(payload) {
      ApiService.post("/conta/reiniciar-whatsapp", payload)
        .then(() => {
          Swal.fire({
            title: "Sucesso!",
            text: "O Whatsapp foi reiniciado com sucesso!",
            icon: "success",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: false
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Erro!",
            text: "O Whatsapp não foi reiniciado!",
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: false
          });
        });
    },
    desativarConexaoContas() {
      this.exibirConexaoContas = false;
    },
    copiarLinkApi(index, login, senha) {
      const api = process.env.VUE_APP_COMUNICANTE_API;
      const link = `${api}/v1/sms/envio?usuario=${login}&senha=${senha}`;
      const tooltip = `tooltipCopiarLinkApi${index}`;

      navigator.clipboard.writeText(link).then(() => {
        this.$refs[tooltip][0].$emit("open");
        setTimeout(() => {
          this.$refs[tooltip][0].$emit("close");
        }, 1000);
      });
    },
    toggleEsquentaChip(index, conta) {
      const esquenta_chip_ativo = conta.esquenta_chip_ativo;
      Swal.fire({
        title: esquenta_chip_ativo
          ? "Desativar Wellon Booster"
          : "Ativar Wellon Booster",
        text: `Deseja realmente ${
          conta.esquenta_chip_ativo ? "desativar" : "ativar"
        } o Wellon Booster para o número ${conta.phone}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.post(
            "/configuracao/toggle-esquenta-chip",
            {
              conta_id: conta.id,
              numero_linha: conta.phone
            },
            false
          ).then((response) => {
            this.contas[index].esquenta_chip_ativo =
              response.data.data.esquenta_chip_ativo == "S";
            Swal.fire({
              title: "Sucesso!",
              text: `Wellon Booster ${
                esquenta_chip_ativo ? "desativado" : "ativado"
              } com sucesso!`,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            });
          });
        }
      });
    },
    toggleNotificacoes() {
      this.is_notification_body_open = !this.is_notification_body_open;
    },
    async pegarNotificacoes() {
      try {
        if (this.stillRequestingNotifications) return;
        this.stillRequestingNotifications = true;
        const res = await ApiService.get(
          API_LINKS.novidades + "/usuario",
          "",
          false
        );
        this.stillRequestingNotifications = false;
        res.data.novidades.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        if (
          this.notificacoes_iniciais.length > 0 &&
          this.notificacoes_iniciais.length < res.data.novidades.length
        ) {
          const latest_notification = res.data.novidades[0];
          this.$toast.add({
            severity: "info",
            summary: latest_notification.nov_titulo,
            detail: latest_notification.nov_descricao,
            closable: false,
            life: 5000,
            group: "notif",
            icon: "pi pi-bell"
          });
        }
        this.notificacoes_iniciais = res.data.novidades;
      } catch (error) {
        clearInterval(this.notifications_interval);
        console.error(error);
      }
    },
    parseDataFormatoBrasileiro(data) {
      return moment(data).format("DD/MM/YYYY HH:mm");
    },
    clickNotificacao(notificacao) {
      this.toggleNotificacoes();
      if ("redirecionar" in notificacao && !notificacao.redirecionar) {
        return;
      }
      this.$router.push({
        name: "novidade.nova",
        params: {
          id: notificacao.novidade_id
        }
      });
    },
    notificationsShortPolling() {
      this.pegarNotificacoes();
      // if (!this.notifications_interval) {
      //   this.notifications_interval = setInterval(() => {
      //     this.pegarNotificacoes();
      //   }, this.shortPollingNotificationIntervalMS);
      // }
    }
  }
};
</script>
<style lang="scss" scoped>
.notification-bell-container {
  position: relative;

  .notification-bell-ball {
    position: relative;
    cursor: pointer;
    margin-right: 12px;
    margin-top: 4px;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: rgb(223, 223, 223);
    }

    transition: all 0.3s ease;
  }
  i {
    font-size: 1.8rem !important;

    @keyframes bellshake {
      0% {
        transform: rotate(0);
      }
      15% {
        transform: rotate(5deg);
      }
      30% {
        transform: rotate(-5deg);
      }
      45% {
        transform: rotate(4deg);
      }
      60% {
        transform: rotate(-4deg);
      }
      75% {
        transform: rotate(2deg);
      }
      85% {
        transform: rotate(-2deg);
      }
      92% {
        transform: rotate(1deg);
      }
      100% {
        transform: rotate(0);
      }
    }

    &.notification-unread {
      animation: bellshake 0.8s ease-in-out;
      animation-iteration-count: infinite;
    }
  }

  .new-notification-news-ball {
    position: absolute;
    bottom: -3px;
    left: -3px;
    width: 17px;
    height: 17px;
    background: #ffa800;
    border-radius: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
  }
}

.notification-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 998 !important;
}
.notification-body-container {
  position: absolute;
  top: 120%;
  right: 0;
  width: 300px;
  height: 400px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;

  display: block;
  overflow-y: auto;

  .notification-body-item {
    padding: 14px;
    cursor: pointer;
    border-bottom: 0.8px solid #e1e1e1;
    position: relative;
    transition: all 0.2s ease;
    &:hover {
      background: rgb(223, 223, 223);
    }
    .unread-notification-ball {
      position: absolute;
      bottom: 50%;
      right: 12px;
      transform: translateY(50%);
      width: 10px;
      height: 10px;
      background: #ffa800;
      border-radius: 100%;
    }
    .notification-body-item-title {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .notification-body-item-description {
      font-size: 1rem;
      margin-bottom: 5px;
    }
    .notification-body-item-date {
      font-size: 0.8rem;
      color: #999;
    }
  }
}
</style>
